import React, { useEffect } from 'react';
import Router  from 'next/router';
import { USER_LOGIN_ROUTE } from '../modules/users';

const HomePage = () => {
  useEffect(() => {
    //always redirect to login page which will check if
    //user is logged in
    Router.replace(USER_LOGIN_ROUTE);
  }, []);

  return (
    <>
    </>
  );
};

export default HomePage;